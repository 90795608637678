define("discourse/plugins/discourse-word-cloud/discourse/routes/wordcloud", ["exports", "discourse/routes/discourse", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/object"], function (_exports, _discourse, _ajaxError, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)("/wordcloud.json").then(result => {
        return _object.default.create({
          words: result.word_count.map(w => {
            return _object.default.create({
              text: w.word,
              href: `/search?q=${w.word}`,
              size: w.count
            });
          })
        });
      }).catch(_ajaxError.popupAjaxError);
    }
  });
});