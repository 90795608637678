define("discourse/plugins/discourse-word-cloud/discourse/word-cloud-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('wordcloud', {
      path: '/wordcloud'
    });
  }
  ;
});